<template>
  <b-row class="border-bottom sticky-top bg-white">
    <div class="page-header">
      <button type="button" @click="gotTo" class="page-header__button">
        <CIcon
          name="arrow-to-left"
          class="page-header__icon page-header__icon--arrow"
        />
      </button>

      <h5 class="page-header__title">
        <span class="page-header__font page-header__font--title" v-if="title">
          {{ title }}
        </span>
      </h5>

      <div class="page-header__addition">
        <slot #addition />
      </div>
    </div>
  </b-row>
</template>

<script>
import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "PageHeader",
  components: {
    CIcon,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },

  methods: {
    gotTo() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/vendors/_variables.scss";

.page-header {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-items: flex-start;
  align-items: center;
  padding: em(15) em(15) em(17);

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: em(24);
    margin-right: em(13);

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &__title {
    margin-bottom: 0;
  }

  &__addition {
    margin-left: auto;
  }

  &__font {
    &--title {
      color: $black;
      font-family: $font-default;
      font-style: normal;
      font-weight: 700;
      font-size: em(16);

      line-height: 1;
    }
  }

  &__icon {
    &--arrow {
      width: em(34);
      height: em(24);
    }
  }
}
</style>
