<template>
  <b-row class="settings-social">
    <b-col>
      <PageHeader :title="$t('general.settingsPages.twitter.title')" />

      <div class="settings-social__settings">
        <div class="settings-social__subtitle">
          <span class="settings-social__font settings-social__font--subtitle">
            {{ $t("general.settingsPages.twitter.subTitle") }}
          </span>
        </div>

        <button
          type="button"
          @click.prevent="setSettings"
          class="base-button settings-social__button settings-social__button--social"
        >
          <span class="settings-social__icon-wrapper">
            <CIcon
              name="twitter"
              class="settings-social__icon settings-social__icon--twitter"
            />
          </span>

          <span class="base-button__font settings-social__font settings-social__font--link">
            {{ $t("general.settingsPages.twitter.button") }}
          </span>
        </button>

        <div class="settings-social__subtitle settings-social__subtitle--2">
          <span class="settings-social__font settings-social__font--subtitle">
            {{ $t("general.settingsPages.twitter.settings.title") }}
          </span>
        </div>

        <div
          class="settings-social__row"
          v-for="button in buttonList"
          :key="button.id"
        >
          <div class="settings-social__text">
            <span class="settings-social__font">
              {{ button.name }}
            </span>
          </div>

          <span class="settings-social__addition">
            <CButtonToggle
              class="settings-social__toggle"
              :class="{ 'settings-social__toggle--disabled': button.disabled }"
              :is-active="button.isActive"
              @click="button.action"
            />
          </span>
        </div>
      </div>

      <div class="settings-social__button-wrapper">
        <button
          type="button"
          @click.prevent="setSettings"
          class="base-button settings-social__button"
        >
          <span class="base-button__font">
            {{ $t("general.privacyPage.button.update") }}
          </span>
        </button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import PageHeader from "@/layout/PageHeader.vue";
import CButtonToggle from "@/features/ui/CButtonToggle.vue";

import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import CIcon from "@/features/ui/CIcon.vue";
export default {
  name: "PageSettingsTwitter",
  mixins: [validationError, waitRequest],
  components: {
    CIcon,
    CButtonToggle,
    PageHeader,
  },
  data() {
    return {
      settings: null,
      isLoginWith: false,
      isAutoTweet: false,
      isRecommend: false,
    };
  },

  computed: {
    buttonList() {
      return [
        {
          id: 1,
          name: this.$t("general.settingsPages.twitter.settings.loginWith"),
          isActive: this.isLoginWith,
          action: () => (this.isLoginWith = !this.isLoginWith),
        },
        {
          id: 2,
          name: this.$t("general.settingsPages.twitter.settings.autoTweet"),
          isActive: this.isAutoTweet,
          action: () => (this.isAutoTweet = !this.isAutoTweet),
        },
        {
          id: 3,
          name: this.$t("general.settingsPages.twitter.settings.recommend"),
          isActive: this.isRecommend,
          action: () => (this.isRecommend = !this.isRecommend),
        },
      ];
    },
  },

  methods: {
    setSettings() {
      console.log("click");
    },
  },
};
</script>

<style scoped lang="scss">
.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 16) em(16, 16);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}

.settings-social {
  $parent: &;

  &__settings {
    margin-top: em(19);
    margin-left: em(-15);
    margin-right: em(-15);
    padding: 0 em(17);
    border-bottom: 1px solid $app-gray-2;
  }

  &__subtitle {
    &--2 {
      margin-top: em(27);
      margin-bottom: em(16);
    }
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: em(19);

    &:last-of-type {
      margin-bottom: em(31);
    }
  }

  &__text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: background-color $time-normal $ease, color $time-normal $ease;
  }

  &__font {
    color: $black;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;

    &--subtitle {
      font-size: em(18);
    }

    &--link {
      color: $white;
      font-size: em(10);
      font-weight: 600;
    }
  }

  &__addition {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
  }

  &__button {
    margin-top: em(17);
    margin-left: auto;

    &--social {
      margin-top: em(15);
      width: 100%;
      justify-content: flex-start;
    }
  }

  &__button-wrapper {
    display: flex;
  }

  &__toggle {
    font-size: 60%; // change toggle default size

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__icon {
    display: inline-block;
    width: em(19);
    min-width: em(19);
    height: em(19);
    margin-right: em(6);
  }
}
</style>
